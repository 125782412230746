import { ROUTES } from 'config/routes';
import { USER_TYPES } from 'config/users';
import { NotificationsTypes } from 'store/notifications/types';

export const HeaderConfig = {
  [USER_TYPES.Farmer]: [
    { value: 'HOME', to: ROUTES.main },
    {
      value: 'ASSESSMENTS',
      to: ROUTES.assessments,
      notificationType: NotificationsTypes.assessments,
    },
    { value: 'RESULTS', to: ROUTES.kpi, notificationType: NotificationsTypes.kpi },
    {
      value: 'DOCUMENTS',
      to: ROUTES.documents,
      notificationType: NotificationsTypes.documents,
    },
    { value: 'SETTINGS', to: ROUTES.settings },
    { value: 'CONTACT US', to: ROUTES.contact },
  ],
  [USER_TYPES.Retailer]: [
    { value: 'HOME', to: ROUTES.main },
    { value: 'SUPPLIERS', to: ROUTES.suppliers },
    { value: 'RESULTS', to: ROUTES.kpi, notificationType: NotificationsTypes.kpi },
    {
      value: 'ASSESSMENTS',
      to: ROUTES.assessments,
      notificationType: NotificationsTypes.assessments,
    },
    {
      value: 'DOCUMENTS',
      to: ROUTES.documents,
      notificationType: NotificationsTypes.documents,
    },
    { value: 'SETTINGS', to: ROUTES.settings },
    { value: 'CONTACT US', to: ROUTES.contact },
  ],
  [USER_TYPES.Processor]: [
    { value: 'HOME', to: ROUTES.main },
    { value: 'SUPPLIERS', to: ROUTES.suppliers },
    {
      value: 'ASSESSMENTS',
      to: ROUTES.assessments,
      notificationType: NotificationsTypes.assessments,
    },
    { value: 'RESULTS', to: ROUTES.kpi, notificationType: NotificationsTypes.kpi },
    {
      value: 'DOCUMENTS',
      to: ROUTES.documents,
      notificationType: NotificationsTypes.documents,
    },
   
    { value: 'SETTINGS', to: ROUTES.settings },
    { value: 'CONTACT US', to: ROUTES.contact },
  ],
};

export const FooterConfig = {
  [USER_TYPES.Farmer]: [
    { value: 'HOME', to: ROUTES.main },
    {
      value: 'ASSESSMENTS',
      to: ROUTES.assessments,
      notificationType: NotificationsTypes.assessments,
    },
    { value: 'RESULTS', to: ROUTES.kpi, notificationType: NotificationsTypes.kpi },
    {
      value: 'DOCUMENTS',
      to: ROUTES.documents,
      notificationType: NotificationsTypes.documents,
    },
    { value: 'SETTINGS', to: ROUTES.settings },
    { value: 'CONTACT US', to: ROUTES.contact },
    { value: 'PRIVACY POLICY', to: ROUTES.privacy },
  ],
  [USER_TYPES.Retailer]: [
    { value: 'HOME', to: ROUTES.main },
    { value: 'SUPPLIERS', to: ROUTES.suppliers },
    { value: 'RESULTS', to: ROUTES.kpi, notificationType: NotificationsTypes.kpi },
    {
      value: 'DOCUMENTS',
      to: ROUTES.documents,
      notificationType: NotificationsTypes.documents,
    },
    { value: 'SETTINGS', to: ROUTES.settings },
    { value: 'CONTACT US', to: ROUTES.contact },
    { value: 'PRIVACY POLICY', to: ROUTES.privacy },
  ],
  [USER_TYPES.Processor]: [
    { value: 'HOME', to: ROUTES.main },
    { value: 'SUPPLIERS', to: ROUTES.suppliers },
    { value: 'RESULTS', to: ROUTES.kpi, notificationType: NotificationsTypes.kpi },
    {
      value: 'DOCUMENTS',
      to: ROUTES.documents,
      notificationType: NotificationsTypes.documents,
    },
    { value: 'SETTINGS', to: ROUTES.settings },
    { value: 'CONTACT US', to: ROUTES.contact },
    { value: 'PRIVACY POLICY', to: ROUTES.privacy },
  ],
};

export const notificationsRoutes = {
  [NotificationsTypes.kpi]: ROUTES.kpi,
  [NotificationsTypes.documents]: ROUTES.documents,
  [NotificationsTypes.assessments]: ROUTES.assessments,
}
export const notificationTexts = {
  [NotificationsTypes.kpi]: ' a new kpi',
  [NotificationsTypes.documents]: ' new documents',
  [NotificationsTypes.assessments]: ' new assessments',
}

export const generateNotificationText = (name, text) => `Hi ${name}, you have ${text}`
